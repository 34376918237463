import React from 'react';

// Funzione per importare dinamicamente tutte le immagini in una cartella
const importAllImages = (r) => {
  return r.keys().map(r);
};

const images = importAllImages(require.context('../assets/images/gallery/', false, /\.(png|jpe?g|svg|gif)$/));

const ImageGallery = () => {
  return (
   
      <div className="annie-homepage-gallery">
        <div className="container-fluid">
          <div className="row align-items-stretch annie-photos" id="annie-section-photos">
            <div className="col-12">
              <div className="row align-items-stretch">
                {images.map((image, index) => (
                  <div className="col-12 col-md-6 col-lg-4 animate-box" data-animate-effect="fadeInUp">
                    <a href={image} className="d-block annie-photo-item" data-caption="Potrait Photo" data-fancybox="gallery"> <img src={image} alt="Image" className="img-fluid" />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row align-items-stretch mt-60">
            <div className="col-12">
              <div className="annie-show-more-container">
                <div className="row align-items-stretch annie-photos" id="annie-section-photos">
                  {images.map((image, index) => (
                    <div className="col-12 col-md-6 col-lg-4 animate-box" data-animate-effect="fadeInUp">
                      <a href={image} className="d-block annie-photo-item" data-caption="Movie Photo" data-fancybox="gallery"> <img src={image} alt="Image" className="img-fluid" />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div className="annie-more-wrapper txt-center animate-box" data-animate-effect="fadeInUp">
                <a href="index.html#" className="annie-more-trigger flex"> <span className="plus flex-center">&nbsp;</span> </a>
              </div>
            </div>
          </div>
        </div>
      </div>



  );
};

export default ImageGallery;
