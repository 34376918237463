
const Services = () => {
    return (
        <div>
            <div className="annie-service">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center"> <span className="heading-meta">What We Do</span>
                            <h2 className="annie-heading animate-box" data-animate-effect="fadeInUp">Services</h2> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 animate-box" data-animate-effect="fadeInUp">
                            <div className="annie-services">
                                <a href="/gallery"> <img src="assets/images/services/02.jpg" className="img-fluid" alt=""/>
                                    <div className="desc">
                                        <div className="con">
                                            <div className="annie-icon"> <i className="fa fa-camera"></i> </div>
                                            <h3>Wedding Shoots</h3> </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 animate-box" data-animate-effect="fadeInUp">
                            <div className="annie-services">
                                <a href="/gallery"> <img src="assets/images/services/06.jpg" className="img-fluid" alt=""/>
                                    <div className="desc">
                                        <div className="con">
                                            <div className="annie-icon"> <i className="fa fa-camera"></i> </div>
                                            <h3><a href="#">Portrait Photography</a></h3> </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 animate-box" data-animate-effect="fadeInUp">
                            <div className="annie-services">
                                <a href="/gallery"> <img src="assets/images/services/05.jpg" className="img-fluid" alt=""/>
                                    <div className="desc">
                                        <div className="con">
                                            <div className="annie-icon"> <i className="fa fa-camera"></i> </div>
                                            <h3><a href="#">Travel Photography</a></h3> </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 animate-box" data-animate-effect="fadeInUp">
                            <div className="annie-services">
                                <a href="/gallery"> <img src="assets/images/services/04.jpg" className="img-fluid" alt=""/>
                                    <div className="desc">
                                        <div className="con">
                                            <div className="annie-icon"> <i className="fa fa-camera"></i> </div>
                                            <h3><a href="#">Product Photography</a></h3> </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 animate-box" data-animate-effect="fadeInUp">
                            <div className="annie-services">
                                <a href="/gallery"> <img src="assets/images/services/01.jpg" className="img-fluid" alt=""/>
                                    <div className="desc">
                                        <div className="con">
                                            <div className="annie-icon"> <i className="fa fa-camera"></i> </div>
                                            <h3><a href="#">Fashion Photography</a></h3> </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 animate-box" data-animate-effect="fadeInUp">
                            <div className="annie-services">
                                <a href="/gallery"> <img src="assets/images/services/03.jpg" className="img-fluid" alt=""/>
                                    <div className="desc">
                                        <div className="con">
                                            <div className="annie-icon"> <i className="fa fa-camera"></i> </div>
                                            <h3><a href="#">Sport Photography</a></h3> </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-60 text-center animate-box" data-animate-effect="fadeInUp"> <span className="heading-meta">Interested to work with us?</span>
                            <div className="form-group">
                                <input type="submit" className="btn btn-contact" value="Contact With Us"/> </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="annie-pricing">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center"> <span className="heading-meta">Packages</span>
                            <h2 className="annie-heading animate-box" data-animate-effect="fadeInUp">Pricing</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="price-box animate-box" data-animate-effect="fadeInUp">
                                <div className="price-box-inner img-grayscale" style={{backgroundImage: "url(../assets/images/pricing/1.jpg)"}}>
                                    <ul>
                                        <li className="pricing-title">
                                            <h3 className="pricing-pt-title">Standart</h3>
                                        </li>
                                        <li className="pricing-prices"> <sup className="pricing-dolar">$</sup> <span className="pricing-price">300</span> </li>
                                        <li className="pricing-content">
                                            <ul>
                                                <li>3 Hours Session</li>
                                                <li>Photo Editing</li>
                                                <li>50 Digital Images</li>
                                                <li>Online Gallery</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="price-box animate-box" data-animate-effect="fadeInUp">
                                <div className="price-box-inner" style={{backgroundImage: "url(../assets/images/pricing/2.jpg)"}}>
                                    <ul>
                                        <li className="pricing-title">
                                            <h3 className="pricing-pt-title">Premium</h3>
                                        </li>
                                        <li className="pricing-prices"> <sup className="pricing-dolar">$</sup> <span className="pricing-price">450</span> </li>
                                        <li className="pricing-content">
                                            <ul>
                                                <li>6 Hours Session</li>
                                                <li>Photo Editing</li>
                                                <li>100 Digital Images</li>
                                                <li>Online Gallery</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="price-box animate-box" data-animate-effect="fadeInUp">
                                <div className="price-box-inner" style={{backgroundImage: "url(../assets/images/pricing/3.jpg)"}}>
                                    <ul>
                                        <li className="pricing-title">
                                            <h3 className="pricing-pt-title">Platinum</h3>
                                        </li>
                                        <li className="pricing-prices"> <sup className="pricing-dolar">$</sup> <span className="pricing-price">750</span> </li>
                                        <li className="pricing-content">
                                            <ul>
                                                <li>12 Hours Session</li>
                                                <li>Photo Editing</li>
                                                <li>250 Digital Images</li>
                                                <li>Online Gallery</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;