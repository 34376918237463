import ImageGallery from "../components/ImageGallery";
const Home = () => {
    return (
        <div>
            <div id="home">
                <div className="annie-hero js-fullheight">
                    <div className="flexslider js-fullheight">
                        <ul className="slides">
                            <li style={{ backgroundImage: "url(../assets/images/slider/01.jpg)" }}>
                                <div className="overlay"></div>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12 js-fullheight slider-text">
                                            <div className="slider-text-inner">
                                                <div className="mx-auto frame-inner">
                                                    <h1>Liberato Giardiello</h1>
                                                    <h2>Photographer</h2> <span className="frame-1"></span> <span className="frame-2"></span> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li style={{ backgroundImage: "url(../assets/images/slider/02.jpg)" }}>
                                <div className="overlay"></div>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12 js-fullheight slider-text">
                                            <div className="slider-text-inner">
                                                <div className="mx-auto frame-inner">
                                                    <h1>Liberato Giardiello</h1>
                                                    <h2>Photographer</h2> <span className="frame-1"></span> <span className="frame-2"></span> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div><div className="annie-homepage-about">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 text-center profile-index animate-box" data-animate-effect="fadeInUp">
                            <p className="annie-about-me">While there is perhaps a province in which the photograph can tell us nothing more than what we see with our own eyes, there is another in which it proves to us how little our eyes permit us to see.</p> <img src="/assets/images/sign.png" className="annie-about-signature" alt="" /> </div>
                    </div>
                </div>
            </div>
            <ImageGallery></ImageGallery>
            </div>
            
        </div>
    );
}
export default Home;