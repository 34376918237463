
const Footer = () => {
    return (
        <div id="annie-footer">
            <div className="annie-narrow-content">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h1>Liberato Giardiello <span>Photographer</span></h1>
                        <p className="annie-lead">&copy; 2024 Liberato Giardiello</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;