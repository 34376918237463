import React from 'react';
import ImageGallery from './components/ImageGallery';
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <div className="preloader-bg"></div>
      <div id="preloader">
          <div id="preloader-status">
              <div className="preloader-position loader"> <span></span> </div>
          </div>
      </div>
      <div className="progress-wrap">
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
              <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/> </svg>
      </div>
      
      <div className="annie-page"> 
        <a href="#" className="js-annie-nav-toggle annie-nav-toggle"><i></i></a>
        <BrowserRouter>
          <NavBar/>
          <div className="annie-main">
              <Routes>
                  <Route exact path="/" element={<Home/>}/>
                  <Route path='/about' element={<About/>}/>
                  <Route path='/services' element={<Services/>}/>
                  <Route path='/gallery' element={<Gallery/>}/>
                  <Route path='/contact' element={<Contact/>}></Route>
              </Routes>
            
            <Footer/>
          </div>
          </BrowserRouter>
      </div>
    </div>
  );
}

export default App;