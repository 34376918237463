import { Link } from "react-router-dom";
const NavBar = () => {
    return (
        <aside className="annie-aside">
            <div className="annie-logo">
                <a href="index.html"><img src="../../assets/images/logo.png" alt="" /></a>
                <h1><a href="index.html">Liberato Giardiello<span>Photographer</span></a></h1> </div>
            <nav className="annie-main-menu">
                <ul>
                    <li className="active"><Link to='/'>Home</Link></li>
                    <li><Link to='about'>About</Link></li>
                    <li><Link to='services'>Services</Link></li>
                    <li><Link to='gallery'>Gallery</Link></li>
                    <li><Link to='contact'>Contact</Link></li>
                </ul>
            </nav>
            <div className="annie-footer">
                <ul>
                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                    <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                    <li><a href="#"><i className="fab fa-behance"></i></a></li>
                    <li><a href="#"><i className="fab fa-500px"></i></a></li>
                </ul>
                <p><small>&copy; 2024 Annie by DuruThemes</small></p>
            </div>
        </aside>
    );
};

export default NavBar;