const About = () => {
    return (
        <div style={{marginBottom:"50px"}}>
            <div className="annie-about">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center"> <span className="heading-meta">Senior</span>
                            <h2 className="annie-heading animate-box" data-animate-effect="fadeInUp">About Me</h2> </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 animate-box" data-animate-effect="fadeInUp"> <img src="assets/images/slider/01.jpg" className="img-fluid mb-30" alt="annie" /> </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 animate-box" data-animate-effect="fadeInUp"> <span className="heading-meta">Photographer</span>
                            <h3 className="annie-about-heading">Liberato Giardiello</h3>
                            <p>Quisque bibendum tincidunt varius. Vestibulum viverra bibendum magna, mattis gravida sapien tincidunt ut. Donec felis nunc, dapibus quis facilisis quis, placerat vitae massa. Curabitur consectetur mi tellus, in dignissim nibh maximus tempus. Cras vel metus nec eros laoreet ullamcorper sed sit amet lectus. Vivamus orci mi, malesuada etteye liberet vithelacinia imperdiet ex. Vivamus egestas nibh quis pulvinar vestibulum. Mauris pellentesque eros vitae tellus dapibus egestas.</p>
                            <p>Quisque bibendum tincidunt varius. Vestibulum viverra bibendum magna, mattis gravida sapien tincidunt ut. Donec felis nunc, dapibus quis facilisis quis, placerat vitae massa. Curabitur consectetur mi tellus, in dignissim nibh maximus tempus. Cras vel metus nec eros laoreet ullamcorper sed sit amet lectus. </p>
                            <p><img src="assets/images/sign.png" className="annie-about-signature" alt="annie" /></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="annie-teams">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center"> <span className="heading-meta">Photographer</span>
                            <h2 className="annie-heading animate-box" data-animate-effect="fadeInUp">Professional Team</h2> </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 animate-box" data-animate-effect="fadeInUp">
                            <div className="annie-team"> <img src="assets/images/team/01.jpg" className="img-fluid" alt="" />
                                <div className="desc">
                                    <div className="con">
                                        <h3><a href="#0">Peter Allen</a></h3> <span>Wedding Photographer</span>
                                        <p className="icon">
                                            <span><a href="#0"><i className="fab fa-twitter"></i></a></span>
                                            <span><a href="#0"><i className="fab fa-instagram"></i></a></span>
                                            <span><a href="#0"><i className="fab fa-behance"></i></a></span>
                                            <span><a href="#0"><i className="fab fa-500px"></i></a></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 animate-box" data-animate-effect="fadeInUp">
                            <div className="annie-team"> <img src="assets/images/team/02.jpg" className="img-fluid" alt="" />
                                <div className="desc">
                                    <div className="con">
                                        <h3><a href="#0">Lena Snow</a></h3> <span>Sport Photographer</span>
                                        <p className="icon">
                                            <span><a href="#0"><i className="fab fa-twitter"></i></a></span>
                                            <span><a href="#0"><i className="fab fa-instagram"></i></a></span>
                                            <span><a href="#0"><i className="fab fa-behance"></i></a></span>
                                            <span><a href="#0"><i className="fab fa-500px"></i></a></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 animate-box" data-animate-effect="fadeInUp">
                            <div className="annie-team"> <img src="assets/images/team/03.jpg" className="img-fluid" alt="" />
                                <div className="desc">
                                    <div className="con">
                                        <h3><a href="#0">Hannah Flynn</a></h3> <span>Model Photographer</span>
                                        <p className="icon">
                                            <span><a href="#0"><i className="fab fa-twitter"></i></a></span>
                                            <span><a href="#0"><i className="fab fa-instagram"></i></a></span>
                                            <span><a href="#0"><i className="fab fa-behance"></i></a></span>
                                            <span><a href="#0"><i className="fab fa-500px"></i></a></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;